import { createAction } from '@reduxjs/toolkit';
import type {
  CheckoutResponse,
  WCartItemDetailView,
  WCartView,
  WCheckoutCartView,
  WCheckoutView,
  WGetCartView,
} from '@zola/svc-web-api-ts-client';
import type { CamelCasedPropertiesDeep } from 'type-fest';

const REQUEST_CART = 'zola/cart/REQUEST_CART';
const RECEIVE_CART = 'zola/cart/RECEIVE_CART';

const REQUEST_UPDATED_CART = 'zola/cart/REQUEST_UPDATED_CART';
const RECEIVE_UPDATED_CART = 'zola/cart/RECEIVE_UPDATED_CART';
const REQUEST_INITIAL_CART_DETAILS = 'zola/cart/REQUEST_INITIAL_CART_DETAILS';
const RECEIVE_INITIAL_CART_DETAILS = 'zola/cart/RECEIVE_INITIAL_CART_DETAILS';
const REQUEST_CREDIT_CARD = 'zola/cart/REQUEST_CREDIT_CARD';
const RECEIVE_CREDIT_CARD = 'zola/cart/RECEIVE_CREDIT_CARD';
export const REQUEST_REMOVE_CREDIT_CARD = 'zola/cart/REQUEST_REMOVE_CREDIT_CARD';
export const RECEIVE_REMOVE_CREDIT_CARD = 'zola/cart/RECEIVE_REMOVE_CREDIT_CARD';
export const REQUEST_APPLY_CREDITS = 'zola/cart/REQUEST_APPLY_CREDITS';
export const REQUEST_REMOVE_CREDITS = 'zola/cart/REQUEST_REMOVE_CREDITS';
export const REQUEST_APPLY_PROMO = 'zola/cart/REQUEST_APPLY_PROMO';
export const REQUEST_REMOVE_PROMO = 'zola/cart/REQUEST_REMOVE_PROMO';
export const REQUEST_SUBMIT_CHECKOUT = 'zola/cart/REQUEST_SUBMIT_CHECKOUT';
export const RECEIVE_SUBMIT_CHECKOUT = 'zola/cart/RECEIVE_SUBMIT_CHECKOUT';
export const REQUEST_BRAINTREE_TOKEN = 'zola/cart/REQUEST_BRAINTREE_TOKEN';
export const RECEIVE_BRAINTREE_TOKEN = 'zola/cart/RECEIVE_BRAINTREE_TOKEN';
const REQUEST_SHIPPING_VALIDATION = 'zola/cart/REQUEST_SHIPPING_VALIDATION';
const RECEIVE_SHIPPING_VALIDATION = 'zola/cart/RECEIVE_SHIPPING_VALIDATION';

// Danger danger, don't remove this.  Its dispatched by component-public-registry cart actions
export const ADD_ITEM = 'zola/cart/ADD_ITEM';
// Danger danger, don't remove this.  Its dispatched by component-public-registry cart actions
export const ADDED_ITEM = 'zola/cart/ADDED_ITEM';

export const ADD_ITEM_TO_CART = 'zola/cart/ADD_ITEM_TO_CART';
export const ADDED_ITEM_TO_CART = 'zola/cart/ADDED_ITEM_TO_CART';
const RECEIVE_DELETED_ITEMS = 'zola/cart/RECEIVE_DELETED_ITEMS';

export const requestCartAction = createAction(REQUEST_CART);

export type ReceivedCartResponse = CamelCasedPropertiesDeep<WGetCartView> | undefined;
export const receiveCartAction = createAction<ReceivedCartResponse>(RECEIVE_CART);

export type AddedItemToCartType = {
  type: typeof ADDED_ITEM_TO_CART;
  payload: WCartView;
};

export const requestUpdatedCartAction = createAction(REQUEST_UPDATED_CART);

export type CartUpdatedResponse = CamelCasedPropertiesDeep<WCheckoutCartView> | undefined;

export const receiveUpdatedCartAction = createAction<CartUpdatedResponse>(RECEIVE_UPDATED_CART);

export const receiveDeletedItemsAction =
  createAction<CamelCasedPropertiesDeep<WCartItemDetailView>[]>(RECEIVE_DELETED_ITEMS);

export const requestInitialCartDetailsAction = createAction(REQUEST_INITIAL_CART_DETAILS);

export const receiveInitialCartDetailsAction = createAction<
  CamelCasedPropertiesDeep<WCheckoutView> | undefined
>(RECEIVE_INITIAL_CART_DETAILS);

export const requestCreditCardAction = createAction(REQUEST_CREDIT_CARD);

export const receiveCreditCardAction = createAction<CreditCardView[]>(RECEIVE_CREDIT_CARD);

export const requestShippingValidationAction = createAction(REQUEST_SHIPPING_VALIDATION);

// TODO: Change to CamelCasedPropertiesDeep<WCheckoutResponseView> which requires updating the reducer to not look for payload.data
export const receiveShippingValidationAction = createAction<
  CamelCasedPropertiesDeep<CheckoutResponse> & { allowBypass: boolean }
>(RECEIVE_SHIPPING_VALIDATION);

/**
 * Credit card brands used for identification, (Visa, Mastercard etc).
 *
 * @see https://github.com/NewAmsterdamLabs/commons/blob/development/commons-types/src/main/java/com/zola/commons/type/CreditCardType.java
 */
export type CreditCardType =
  | 'American Express'
  | 'Visa'
  | 'Mastercard'
  | 'Discover'
  | 'Diners Club'
  | 'JCB'
  | 'Unknown';

// This is from svc-order, via web-zola, which does a mapping
export type CreditCardView = {
  id: number;
  cardholder_name: string;
  card_reference: string;
  /** CreditCardType enum */
  credit_card_type: CreditCardType;
  last_four_digits: string;
  expiration_month: number;
  expiration_year: number;
  default_card: boolean;
  created_at: number;
  updated_at: number;
  billing_address: {
    streetAddress1: string;
    streetAddress2: string | null;
    city: string;
    state: string;
    postalCode: string;
    /** ALPHA-3 country code */
    country: string;
    phoneNumber: string;
  };
};
