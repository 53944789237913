import type { CheckoutData, Details, WCartItemDetailView } from '@zola/svc-web-api-ts-client';
import { Reducer } from 'redux';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import {
  CreditCardView,
  receiveCartAction,
  receiveCreditCardAction,
  receiveDeletedItemsAction,
  receiveInitialCartDetailsAction,
  receiveShippingValidationAction,
  receiveUpdatedCartAction,
  requestCartAction,
  requestUpdatedCartAction,
} from '../actions/types/CartActionTypes';

type CartState = {
  busy: boolean;
  cartId?: string;
  cartMessage?: string | null;
  checkoutData?: CamelCasedPropertiesDeep<CheckoutData>;
  details?: CamelCasedPropertiesDeep<Details>;
  freeShipping?: { amountLeft?: number; metThreshold?: boolean } | null;
  initialized: boolean;
  items: CamelCasedPropertiesDeep<WCartItemDetailView>[];
  promo?: {
    code?: string;
    label?: string;
    terms?: string;
  };

  /** @deprecated - this doesnt' appear to be read anywhere */
  size: number;
  totals: {
    subtotal?: number;
    tax?: number;
    deliverySurcharge?: number;
    supplyChainFee?: number;
    shipping?: number;
    creditApplied?: number;
    discountsApplied?: number;
    handlingFee?: number;
    total?: number;
    amountSaved?: number;
  };
  validateShipping?: boolean;
  shippingError?: string | undefined;

  // Saved card.. Just one... but its an array, so it will be accessed with card[0]
  card?: CreditCardView[];
};

const initialState = {
  details: {},
  busy: false,
  initialized: false,
  size: 0,
  items: [],
  totals: {},
  validateShipping: true,
  freeShipping: null, // temporary
};

const cartReducer: Reducer<CartState> = (state = initialState, action) => {
  if (requestCartAction.match(action) || requestUpdatedCartAction.match(action)) {
    return { ...state, busy: true };
  }
  if (receiveCartAction.match(action)) {
    return {
      ...state,
      busy: false,
      initialized: true,
      cartMessage: action.payload?.cartMessage,
      size: action.payload?.items?.length || 0,
      items: action.payload?.items || [],
      totals: action.payload?.totals ?? {},
      freeShipping: action.payload?.freeShipping ?? null,
    };
  }
  if (receiveUpdatedCartAction.match(action)) {
    return {
      ...state,
      cartMessage: action.payload?.cartMessage,
      details: action.payload?.details,
      items: action.payload?.items || [],
      promo: action.payload?.promo,
      totals: action.payload?.totals ?? {},
    };
  }
  if (receiveDeletedItemsAction.match(action)) {
    return { ...state, deletedCartItems: action.payload };
  }

  if (receiveInitialCartDetailsAction.match(action)) {
    return {
      ...state,
      cartMessage: action.payload?.cart?.cartMessage,
      details: action.payload?.cart?.details,
      promo: action.payload?.cart?.promo,
      totals: action.payload?.cart?.totals ?? {},
      checkoutData: action.payload?.checkoutData,
      cartId: action.payload?.checkoutData && action.payload.checkoutData.cartId,
    };
  }

  if (receiveCreditCardAction.match(action)) {
    return { ...state, card: action.payload };
  }

  if (receiveShippingValidationAction.match(action)) {
    const validateShipping =
      action.payload &&
      !action.payload.allowBypass &&
      (!action.payload.data || !action.payload.data.isValidating);
    const shippingError = action.payload.data && action.payload.data.userMessage;
    return {
      ...state,
      shippingError,
      validateShipping,
    };
  }

  return state;
};

export default cartReducer;
